import React, { Component, ReactNode } from 'react';
import Container from '../container';
import ArticleAuthor from './articleAuthor';
import Title from './title';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Maybe, Node__Author as nodeAuthor } from '../../../graphql-types';
import ShareAnimatedButton from './shareAnimatedButton';
import { Link } from 'gatsby';

interface Props {
  title?: Maybe<string>;
  profilePhoto: IGatsbyImageData;
  author?: Maybe<Pick<nodeAuthor, 'title' | 'field_author_profession'>>;
  profileAlt?: Maybe<string>;
  timeToRead: string;
  department?: Maybe<string>;
  mail: {
    subject?: Maybe<string>;
    body?: Maybe<string>;
    email?: Maybe<string>;
  };
}

interface State {
  location: string;
}

class ArticleHead extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      location: '',
    };
  }

  render(): ReactNode {
    const { title, author, profilePhoto, timeToRead, profileAlt, mail, department } = this.props;

    return (
      <Container>
        <div className="margin-lateral border-t-2 border-gray-300 mobile:pb-0">
          <div className="text-center font-semibold pt-14 pb-10 laptop-standard:pt-25 laptop-standard:pb-14">
            <Link to="/insights">
              <p>Insights</p>
            </Link>
          </div>
          <Title title={title} />
        </div>
        <div className="margin-lateral pt-13 pb-13">
          <div className="flex flex-wrap tablet:flex-nowrap items-center">
            <div className="flex flex-initial mobile:w-11/12 tablet:w-10/12 laptop-standard:w-11/12">
              <ArticleAuthor
                profilePhoto={profilePhoto}
                author={author}
                department={department}
                timeToRead={timeToRead}
                profileAlt={profileAlt ?? ''}
                captionClassname="text-gray-500"
              />
            </div>
            <ShareAnimatedButton mailSubject={mail.subject ?? ''} mailBody={mail.body ?? ''} />
          </div>
        </div>
      </Container>
    );
  }
}

export default ArticleHead;
