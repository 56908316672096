import React, { Component, ReactNode } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  image?: IGatsbyImageData;
  className?: string;
  imgAlt?: string | null;
}

class RenderImage extends Component<Props> {
  render(): ReactNode {
    if (!this.props.image) {
      return null;
    }
    const className = this.props.className ?? 'flex content-center relative pt-0';

    return (
      <div>
        <div className={className}>
          <GatsbyImage
            image={this.props.image}
            className={'absolute h-full w-full object-cover'}
            alt={this.props.imgAlt ?? ''}
            style={{ position: 'relative', overflow: 'hidden' }}
          />
        </div>
      </div>
    );
  }
}

export default RenderImage;
