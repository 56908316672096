import React from 'react';
import ReactHtmlParser, { convertNodeToElement, Transform } from 'react-html-parser';
import ArticleTout from '../components/article/articleBanner';
import Body from '../components/article/body';
import ArticleHead from '../components/article/articleHead';
import RenderImage from '../components/article/renderImage';
import Layout from '../components/layout';
import Quote from '../components/quote';
import * as styles from '../styles/article.module.css';
import { GetAllArticlesQuery } from '../../graphql-types';

interface Props {
  pageContext: {
    articleData: GetAllArticlesQuery['allNodeArticle']['nodes'][0];
  };
}

const quoteClass =
  'mobile:mx-6 tablet:mx-16 laptop-large:mx-32 laptop-large:pl-20 tablet:pl-10 tablet:pt-25 tablet:pb-25 mobile:pt-13 mobile:pb-13';

function transform(node: any, index: any) {
  const needExtraSpacing = ['li'].includes(node.name);
  const renderedNode = convertNodeToElement(node, index, transform);
  if (needExtraSpacing) {
    return (
      <div key={`parent-${node.name}-${index}`}>
        {renderedNode}
        {needExtraSpacing ? <br /> : null}
      </div>
    );
  }
  return renderedNode;
}

function renderDOMChildren(children: any[]) {
  return children?.map((child, index) => convertNodeToElement(child, index, transform));
}

export const transformBody: Transform = function (node, index) {
  if (node.type !== 'tag') {
    return convertNodeToElement(node, index, transform);
  }

  if (node.name === 'img' && process.env.GATSBY_DRUPAL_URL) {
    if (node?.attribs?.src && node.attribs.src.startsWith('/sites/default')) {
      node.attribs.src = process.env.GATSBY_DRUPAL_URL.concat(node.attribs.src);
    }
  } else if (node.name === 'blockquote') {
    return (
      <Quote
        classContainer="bg-yellow-500 text-black px-4 laptop-standard:mt-3"
        quote={renderDOMChildren(node?.children)}
        textClass={quoteClass}
        key={`blockquote-${index}`}
      />
    );
  } else if (['p', 'ul'].includes(node.name)) {
    node.attribs.class = (node.attribs.class ?? '').concat(' body2');
  }

  if (['h1', 'h2', 'h3', 'h4', 'h5', 'img', 'p', 'ul'].includes(node.name)) {
    let extraStyle = '';
    switch (node.name) {
      case 'ul':
        extraStyle = `${styles.listItemDisc} space-y-6 mobile:mx-4 tablet:mx-8 laptop-standard:mx-16`;
        break;
      default:
        break;
    }

    const childrenRendered = convertNodeToElement(node, index, transform);

    return (
      <Body
        key={`${node.name}-${index}`}
        className={`px-10 laptop-standard:px-40 laptop-large:px-56 laptop-wide:px-64 desktop-standard:px-65 desktop-large:px-74 block overflow-hidden ${extraStyle} ${styles.paragraphspacing}`}
      >
        {extraStyle ? <div className={extraStyle}>{childrenRendered}</div> : childrenRendered}
      </Body>
    );
  }

  return convertNodeToElement(node, index, transform);
};

const ArticleBuilder: React.FC<Props> = ({ pageContext }) => {
  const { articleData } = pageContext;

  return (
    <Layout
      seo={{
        title: articleData?.title ?? '',
        author: articleData?.relationships?.field_author?.title ?? '',
        keywords:
          articleData?.relationships?.field_article_topics
            ?.map((article) => article?.name)
            ?.toString() ?? null,
        description: articleData.field_share_description ?? null,
        image:
          articleData.relationships?.field_share_image?.relationships?.field_media_image?.localFile
            ?.publicURL ?? null,
      }}
    >
      <ArticleHead
        department={articleData.field_caption}
        title={articleData?.field_article_title?.processed ?? articleData?.title}
        profilePhoto={
          articleData?.relationships?.field_author?.relationships?.field_image?.relationships
            ?.field_media_image?.localFile?.childImageSharp?.gatsbyImageData
        }
        profileAlt={
          articleData.relationships?.field_author?.relationships?.field_image?.field_media_image
            ?.alt
        }
        author={articleData?.relationships?.field_author}
        timeToRead={articleData?.field_read_time ? `${articleData.field_read_time} min read` : ''}
        mail={{
          subject: articleData?.title,
          body: articleData?.field_share_description,
        }}
      />
      <RenderImage
        className="max-width padding-lateral"
        image={
          articleData?.relationships?.field_article_image?.relationships?.field_media_image
            ?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgAlt={articleData.relationships?.field_article_image?.field_media_image?.alt}
      />
      <div className="mobile:pt-25 tablet:pt-45 mobile:pb-20 tablet:pb-35">
        {articleData?.field_body?.processed &&
          ReactHtmlParser(articleData.field_body.processed, {
            transform: transformBody,
          })}
      </div>
      <ArticleTout
        topics={articleData.relationships?.field_article_topics?.map((article) => article?.name)}
        articleID={articleData.relationships?.field_featured_article_tout?.field_id}
        currentArticleID={articleData.field_id}
      />
    </Layout>
  );
};

export default ArticleBuilder;
