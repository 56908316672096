import React, { Component, ReactNode } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Maybe, Node__Author as nodeAuthor } from '../../../graphql-types';

interface Props {
  author?: Maybe<Pick<nodeAuthor, 'title' | 'field_author_profession'>>;
  profilePhoto: IGatsbyImageData;
  profileAlt: string;
  imageSize?: string;
  timeToRead: string;
  landingPage?: boolean;
  className?: string;
  captionClassname?: string;
  department?: Maybe<string>;
  authorNameClassName?: string;
  classNameImageContainer?: string;
}

class ArticleAuthor extends Component<Props> {
  render(): ReactNode {
    if (!this.props.author || !this.props.author.title) {
      return null;
    }
    const authorInfo = this.props.landingPage ? (
      <p className="invisible tablet:visible mobile:h-0 mobile:iconSm font-semibold">
        {this.props.author.title}
        {!this.props.author.field_author_profession
          ? ''
          : `, ${this.props.author.field_author_profession}`}
      </p>
    ) : (
      <p className="invisible tablet:visible mobile:h-0 mobile:iconSm font-semibold">
        By {this.props.author.title}
        {!this.props.author.field_author_profession
          ? ''
          : `, ${this.props.author.field_author_profession}`}
      </p>
    );

    return (
      <div
        className={`article-profile-author-container mobile:space-x-3 tablet:space-x-6 laptop-standard:space-x-0 laptop-wide:space-x-0 ${
          this.props.className ?? ''
        }`}
      >
        <div
          className={`flex mobile:w-14 tablet:w-16 laptop-standard:w-24 mobile:pt-2 tablet:pt-1 ${
            this.props.classNameImageContainer ?? ''
          }`}
        >
          <GatsbyImage
            image={this.props.profilePhoto}
            className={`rounded-full roundedPhoto 
            ${
              this.props.imageSize ??
              'mobile:h-11 mobile:w-11 tablet:w-14 tablet:h-14 laptop-standard:h-20 laptop-standard:w-20'
            }`}
            alt={this.props.profileAlt}
          />
        </div>
        <div
          className={`mobile:flex-initial laptop-standard:flex-1 mobile:w-9/12 tablet:w-8/12 laptop-standard:w-11/12 space-y-2 tablet:pt-2 tablet:space-y-1
        ${
          this.props.authorNameClassName ??
          'laptop-standard:pt-1 laptop-large:pt-3 laptop-standard:pl-0 '
        }`}
        >
          {authorInfo}
          <p className="mobile:visible tablet:invisible mobile:iconSm font-semibold">
            {this.props.author.title}
            {!this.props.author.field_author_profession
              ? ''
              : `, ${this.props.author.field_author_profession}`}
          </p>
          <p
            className={`mobile:iconSm laptop-standard:iconLg mobile:opacity-80 ${
              this.props.captionClassname ?? ''
            }`}
          >
            {this.props.department ? (
              this.props.timeToRead ? (
                <>
                  {this.props.department}
                  <span className="px-2">|</span>
                </>
              ) : (
                this.props.department
              )
            ) : (
              ''
            )}
            {this.props.timeToRead ?? ''}
          </p>
        </div>
      </div>
    );
  }
}
export default ArticleAuthor;
